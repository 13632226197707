import "./main.css";
import { Elm } from "./Main.elm";
import * as serviceWorker from "./serviceWorker";
import { Piano, Brass, ChorusStrings } from "./wave-tables";

const app = Elm.Main.init({
  node: document.getElementById("root"),
});

let audioCtx,
  oscillatorNode,
  gainNode,
  stereoPannerNode,
  delayNode,
  convolverNode,
  lfoNode;

app.ports.create.subscribe(function ({
  gain: gain100,
  frequency,
  pan: pan100,
  delayTime: delayTime100,
  waveform,
  lfoFrequency: lfoFrequency100,
  lfoWaveform,
}) {
  const gain = gain100 / 100;
  const pan = pan100 / 100;
  const delayTime = delayTime100 / 100;
  const lfoFrequency = lfoFrequency100 / 100;
  audioCtx = new AudioContext();

  oscillatorNode = new OscillatorNode(audioCtx, {
    frequency: frequency,
    type: waveform,
  });

  lfoNode = new OscillatorNode(audioCtx, {
    frequency: lfoFrequency,
    type: "sine",
  });

  lfoNode.start();

  gainNode = new GainNode(audioCtx, {
    gain,
  });

  lfoNode.connect(gainNode.gain);

  stereoPannerNode = new StereoPannerNode(audioCtx, {
    pan,
  });

  delayNode = new DelayNode(audioCtx, {
    delayTime,
  });

  convolverNode = new ConvolverNode(audioCtx);

  // Start of not my code

  let soundSource;
  const ajaxRequest = new XMLHttpRequest();

  ajaxRequest.open("GET", "concert-crowd.mp3", true);

  ajaxRequest.responseType = "arraybuffer";

  ajaxRequest.onload = function () {
    const audioData = ajaxRequest.response;

    audioCtx.decodeAudioData(
      audioData,
      function (buffer) {
        soundSource = audioCtx.createBufferSource();
        convolverNode.buffer = buffer;
      },
      function (e) {
        console.log("Error with decoding audio data" + e.err);
      }
    );
  };

  ajaxRequest.send();

  // end of not my code

  oscillatorNode.connect(gainNode);
  gainNode.connect(stereoPannerNode);
  stereoPannerNode.connect(delayNode);

  oscillatorNode.start(0);
});

app.ports.changeSetting.subscribe(function ({
  gain,
  frequency,
  pan,
  delayTime,
  convolverOn,
  waveform,
  lfoFrequency,
}) {
  oscillatorNode.frequency.value = frequency;
  lfoNode.frequency.value = lfoFrequency / 100;
  stereoPannerNode.pan.value = pan / 100;
  delayNode.delayTime.value = delayTime / 100;
});

app.ports.changeWaveform.subscribe(function (message) {
  // @Todo add more waveforms
  let periodicWave;
  switch (message) {
    case "sine":
    case "sawtooth":
    case "square":
    case "triangle":
      oscillatorNode.type = message;
      break;
    case "piano":
      oscillatorNode.setPeriodicWave(
        new PeriodicWave(audioCtx, {
          real: Piano.real,
          imag: Piano.imag,
        })
      );
      break;
    case "chorus_strings":
      oscillatorNode.setPeriodicWave(
        new PeriodicWave(audioCtx, {
          real: ChorusStrings.real,
          imag: ChorusStrings.imag,
        })
      );
      break;
    case "brass":
      oscillatorNode.setPeriodicWave(
        new PeriodicWave(audioCtx, {
          real: Brass.real,
          imag: Brass.imag,
        })
      );
  }
});

app.ports.setConvolver.subscribe(function (message) {
  if (message) {
    convolverNode.connect(gainNode);
    oscillatorNode.disconnect(gainNode);
    oscillatorNode.connect(convolverNode);
  } else {
    convolverNode.disconnect(gainNode);
    oscillatorNode.connect(gainNode);
  }
});

app.ports.play.subscribe(function () {
  delayNode.connect(audioCtx.destination);
});

app.ports.stop.subscribe(function (message) {
  delayNode.disconnect(audioCtx.destination);
});

serviceWorker.unregister();
