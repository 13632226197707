export default {
  real: [
    0.0, -0.051411, -0.085181, -0.45332, -0.042603, 0.04083, -0.204758, 0.02603,
    -0.147721, -0.007584, -0.07638, -0.003137, -0.064948, 0.003745, 0.120424,
    -0.002757, 0.016572, 0.001757, 0.005585, 0.001412, -0.011589, -0.001676,
    -0.010206, -0.005745, 0.002632, -0.001359, -0.001483, -0.003481, -0.005393,
    0.003601, 0.004419, -0.002671, 0.001664, 0.001878, -0.003127, -0.003645,
    -0.002282, 0.00249, 0.000949, 0.002633, 0.000153, -0.000786, -0.000054,
    0.00141, 0.001927, -0.001851, 0.000077, 0.001024, -0.000413, -0.001542,
    0.001697, 0.00068, -0.001677, -0.000879, 0.001588, -0.001163, -0.001416,
    0.001599, 0.001367, -0.001552, -0.001309, 0.001185, 0.001161, 0.001016,
    -0.001293, -0.000196, 0.000959, -0.000821, 0.001153, 0.001188, 0.001041,
    0.000318, -0.001352, 0.001337, 0.001352, -0.001001, -0.000867, -0.000503,
    0.00075, -0.00049, 0.001242, -0.000144, 0.000558, 0.001006, 0.000314,
    -0.001167, 0.001112, 0.000935, 0.000987, 0.000888, -0.001061, -0.000059,
    0.001039, 0.001154, 0.000665, 0.001027, -0.001083, 0.001052, 0.000776,
    0.000295, -0.000945, 0.000866, 0.000894, 0.000694, -0.000524, 0.000199,
    0.001024, -0.000996, -0.000056, 0.001027, 0.000764, 0.000793, -0.000985,
    0.000179, 0.000968, 0.000549, -0.000967, 0.000655, -0.000677, -0.000977,
    0.000936, -0.000254, -0.000167, 0.000703, 0.000134, -0.000266, -0.000774,
    -0.000891, 0.000514, 0.000874, 0.00071, 0.000771, 0.000837, -0.00025,
    0.00048, 0.000178, -0.000902, 0.00075, 0.000911, 0.000907, -0.000625,
    0.000592, 0.000491, 0.000866, 0.000511, -0.000084, 0.000799, 0.000597,
    0.000879, 0.000869, 0.000822, 0.000618, -0.00007, -0.000006, 0.000857,
    0.000695, -0.000365, 0.00055, 0.000638, 0.000077, -0.000568, 0.00071,
    0.000839, 0.000761, 0.000202, 0.000632, -0.00065, -0.000464, 0.000665,
    0.000825, -0.000102, 0.000763, 0.0004, 0.000587, -0.000623, 0.00078,
    -0.000363, 0.000494, 0.000198, 0.000797, 0.000797, 0.000726, 0.00044,
    0.000572, 0.00073, 0.000789, 0.000294, 0.000721, 0.000679, -0.000756,
    -0.000439, 0.000544, 0.000088, 0.00082, 0.000736, 0.000641, 0.000673,
    0.000458, 0.000783, -0.000781, -0.000762, 0.000649, 0.000075, 0.000591,
    -0.00016, 0.000431, -0.000469, -0.00047, -0.000557, -0.00057, -0.000559,
    -0.000653, -0.000726, -0.00076, -0.00076, -0.000758, -0.000773, -0.000775,
    -0.00077, -0.000758, -0.000768, -0.000767, -0.00076, -0.000741, -0.00076,
    -0.00076, -0.00075, -0.000726, -0.000752, -0.000753, -0.000739, -0.000711,
    -0.000744, -0.000747, -0.00073, -0.000697, -0.000737, -0.00074, -0.00072,
    -0.000685, -0.00073, -0.000734, -0.000712, -0.000717, -0.00077, -0.000773,
    -0.000748, -0.000705, -0.000763, -0.000767, -0.00074, -0.000695, -0.000757,
    -0.00076, -0.000732, -0.000685, -0.000751, -0.000754, -0.000725, -0.000676,
    -0.000745, -0.000747, -0.000717, -0.000668, -0.000739, -0.000741, -0.000711,
    -0.00066, -0.000733, -0.000735, -0.000704, -0.000652, -0.000728, -0.000729,
    -0.000698, -0.000645, -0.000723, -0.000723, -0.000692, -0.000638, -0.000718,
    -0.000717, -0.000686, -0.000632, -0.000713, -0.000711, -0.000681, -0.000626,
    -0.000708, -0.000706, -0.000676, -0.00062, -0.000703, -0.0007, -0.00067,
    -0.000614, -0.000698, -0.000695, -0.000665, -0.000608, -0.000694, -0.000689,
    -0.000661, -0.000603, -0.000689, -0.000684, -0.000658, -0.000598, -0.000685,
    -0.000679, -0.00065, -0.000593, -0.00068, -0.000674, -0.000648, -0.00059,
    -0.000677, -0.000669, -0.000644, -0.000585, -0.000672, -0.000664, -0.00064,
    -0.000581, -0.000668, -0.000659, -0.000635, -0.000576, -0.000664, -0.000655,
    -0.000633, -0.000572, -0.00066, -0.00065, -0.00063, -0.000568, -0.000657,
    -0.000645, -0.000626, -0.000564, -0.000653, -0.000641, -0.000621, -0.00056,
    -0.000649, -0.000637, -0.000619, -0.000557, -0.000646, -0.000632, -0.000614,
    -0.000553, -0.000642, -0.000628, -0.000611, -0.00055, -0.000639, -0.000624,
    -0.000608, -0.000547, -0.000635, -0.00062, -0.000606, -0.000543, -0.000632,
    -0.000597, -0.000584, -0.000524, -0.000609, -0.000594, -0.000581, -0.000521,
    -0.000606, -0.00059, -0.000578, -0.000518, -0.000603, -0.000586, -0.000576,
    -0.000515, -0.0006, -0.000582, -0.000573, -0.000512, -0.000597, -0.000579,
    -0.000569, -0.000509, -0.000594, -0.000575, -0.000567, -0.000507, -0.000591,
    -0.000572, -0.000565, -0.000504, -0.000588, -0.000568, -0.000562, -0.000501,
    -0.000585, -0.000566, -0.00056, -0.000499, -0.000582, -0.000562, -0.000557,
    -0.000497, -0.00058, -0.000559, -0.000555, -0.000495, -0.000577, -0.000556,
    -0.000553, -0.000492, -0.000574, -0.000552, -0.000549, -0.000489, -0.000572,
    -0.000549, -0.000547, -0.000487, -0.000569, -0.000546, -0.000546, -0.000484,
    -0.000566, -0.000544, -0.000543, -0.000483, -0.000564, -0.000541, -0.00054,
    -0.000479, -0.000561, -0.000537, -0.000541, -0.000477, -0.000559, -0.000535,
    -0.000537, -0.000476, -0.000556, -0.000531, -0.000537, -0.000468, -0.000553,
    -0.000529, -0.000542, -0.000471, -0.000551, -0.000528, -0.000528, -0.000463,
    -0.000467, -0.00045, -0.000454, -0.00041, -0.000469, -0.000445, -0.000453,
    -0.000397, -0.000467, -0.000445, -0.000451, -0.000398, -0.000465, -0.000443,
    -0.000448, -0.000395, -0.000463, -0.00044, -0.000447, -0.000396, -0.000461,
    -0.000438, -0.000445, -0.000393, -0.000459, -0.000436, -0.000444, -0.000391,
    -0.000457, -0.000434, -0.000442, -0.000389, -0.000456, -0.000432, -0.00044,
    -0.000388, -0.000454, -0.00043, -0.000439, -0.000387, -0.000452, -0.000428,
    -0.000438, -0.000386, -0.00045, -0.000426, -0.000435, -0.000383, -0.000449,
    -0.000424, -0.000434, -0.000383, -0.000447, -0.000422, -0.00043, -0.00038,
    -0.000445, -0.000419, -0.000431, -0.00038, -0.000443, -0.000417, -0.000435,
    -0.000376, -0.000442, -0.000416, -0.000427, -0.000376, -0.00044, -0.000415,
    -0.000424, -0.000374, -0.000438, -0.000414, -0.000425, -0.000374, -0.000437,
    -0.000412, -0.000423, -0.000372, -0.000435, -0.00041, -0.000422, -0.000371,
    -0.000434, -0.000408, -0.000421, -0.000369, -0.000432, -0.000406, -0.00042,
    -0.000368, -0.00043, -0.000404, -0.000418, -0.000367, -0.000429, -0.000402,
    -0.000417, -0.000365, -0.000427, -0.000402, -0.000415, -0.000365, -0.000426,
    -0.000399, -0.000415, -0.000364, -0.000424, -0.000398, -0.000412, -0.000362,
    -0.000423, -0.000396, -0.000411, -0.000361, -0.000421, -0.000395, -0.00041,
    -0.000359, -0.00042, -0.000393, -0.000409, -0.000358, -0.000419, -0.000391,
    -0.000408, -0.000357, -0.000417, -0.00039, -0.000407, -0.000356, -0.000416,
    -0.000389, -0.000405, -0.000355, -0.000414, -0.000387, -0.000404, -0.000353,
    -0.000413, -0.000385, -0.000402, -0.000352, -0.000412, -0.000384, -0.000401,
    -0.000352, -0.00041, -0.000383, -0.000401, -0.00035, -0.000409, -0.000327,
    -0.000342, -0.000299, -0.00035, -0.000326, -0.000342, -0.000299, -0.000349,
    -0.000325, -0.00034, -0.000298, -0.000347, -0.000324, -0.000339, -0.000297,
    -0.000346, -0.000322, -0.00034, -0.000295, -0.000345, -0.000321, -0.000336,
    -0.000294, -0.000344, -0.00032, -0.000335, -0.000295, -0.000343, -0.00032,
    -0.000335, -0.000293, -0.000342, -0.000317, -0.000336, -0.000293, -0.000341,
    -0.000316, -0.000333, -0.000292, -0.00034, -0.000315, -0.000333, -0.000291,
    -0.000339, -0.000315, -0.000333, -0.00029, -0.000338, -0.000313, -0.000331,
    -0.000289, -0.000337, -0.000313, -0.00033, -0.000288, -0.000336, -0.000312,
    -0.000329, -0.000287, -0.000335, -0.000309, -0.000328, -0.000286, -0.000334,
    -0.000309, -0.000327, -0.000286, -0.000333, -0.000308, -0.000326, -0.000285,
    -0.000332, -0.000308, -0.000325, -0.000285, -0.000331, -0.000307, -0.000324,
    -0.000283, -0.00033, -0.000305, -0.000323, -0.000283, -0.000329, -0.000305,
    -0.000322, -0.000282, -0.000328, -0.000304, -0.000322, -0.000281, -0.000327,
    -0.000303, -0.000321, -0.00028, -0.000326, -0.000302, -0.00032, -0.00028,
    -0.000325, -0.000301, -0.00032, -0.000279, -0.000324, -0.000299, -0.000319,
    -0.000279, -0.000324, -0.000299, -0.000318, -0.000278, -0.000323, -0.000298,
    -0.000317, -0.000277, -0.000322, -0.000297, -0.000316, -0.000276, -0.000321,
    -0.000296, -0.000315, -0.000276, -0.00032, -0.000295, -0.000314, -0.000274,
    -0.000319, -0.000294, -0.000314, -0.000275, -0.000318, -0.000293, -0.000314,
    -0.000273, -0.000318, -0.000292, -0.000313, -0.000273, -0.000317, -0.000292,
    -0.000312, -0.000272, -0.000316, -0.000291, -0.000311, -0.000272, -0.000315,
    -0.00029, -0.000309, -0.000271, -0.000314, -0.000289, -0.000311, -0.00027,
    -0.000313, -0.000287, -0.00031, -0.00027, -0.000313, -0.000287, -0.000307,
    -0.000267, -0.000312, -0.000286, -0.000308, -0.000268, -0.000311, -0.000286,
    -0.000306, -0.000267, -0.00031, -0.000284, -0.000306, -0.000267, -0.000309,
    -0.000284, -0.000305, -0.000266, -0.000309, -0.000284, -0.000304, -0.000265,
    -0.000308, -0.000282, -0.000304, -0.000266, -0.000307, -0.000281, -0.000302,
    -0.000264, -0.000306, -0.000281, -0.000302, -0.000264, -0.000306, -0.00028,
    -0.000302, -0.000263, -0.000305, -0.000279, -0.0003, -0.000262, -0.000304,
    -0.000279, -0.0003, -0.000262, -0.000303, -0.000278, -0.000299, -0.000262,
    -0.000303, -0.000278, -0.000299, -0.000261, -0.000302, -0.000276, -0.000298,
    -0.000261, -0.000301, -0.000275, -0.000297, -0.00026, -0.0003, -0.000273,
    -0.000297, -0.000261, -0.0003, -0.000273, -0.000298, -0.000257, -0.000299,
    -0.000273, -0.000296, -0.000258, -0.000298, -0.000273, -0.000294, -0.000257,
    -0.000298, -0.000272, -0.000293, -0.000257, -0.000297, -0.000272, -0.000294,
    -0.000254, -0.000296, -0.00027, -0.000293, -0.000255, -0.000295, -0.000272,
    -0.000292, -0.000253, -0.000295, -0.000269, -0.000292, -0.000253, -0.000294,
    -0.000269, -0.000291, -0.000253, -0.000294, -0.000268, -0.00029, -0.000254,
    -0.000293, -0.000268, -0.00029, -0.000253, -0.000292, -0.000267, -0.000289,
    -0.00025, -0.000292, -0.000264, -0.000289, -0.000252, -0.000291, -0.000265,
    -0.000286, -0.000247, -0.00029, -0.000264, -0.000289, -0.000252, -0.00029,
    -0.000266, -0.000282, -0.000249, -0.000288, -0.000264, -0.000285, -0.000265,
    -0.000288, -0.000268, -0.000283, -0.00022, -0.000254, -0.000243, -0.000252,
    -0.000221, -0.000254, -0.000234, -0.000253, -0.000217, -0.000253, -0.00023,
    -0.000249, -0.000219, -0.000253, -0.000233, -0.000246, -0.000218, -0.000252,
    -0.00023, -0.000249, -0.000219, -0.000252, -0.000229, -0.00025, -0.000217,
    -0.000251, -0.00023, -0.000249, -0.000216, -0.000251, -0.000227, -0.000248,
    -0.000217, -0.00025, -0.000229, -0.000248, -0.000216, -0.00025, -0.000227,
    -0.000248, -0.000216, -0.000249, -0.000227, -0.000245, -0.000214, -0.000249,
    -0.000225, -0.000247, -0.000215, -0.000248, -0.000227, -0.000246, -0.000214,
    -0.000248, -0.000225, -0.000246, -0.000215, -0.000247, -0.000223, -0.000244,
    -0.000214, -0.000246, -0.000224, -0.000244, -0.000214, -0.000246, -0.000223,
    -0.000245, -0.000212, -0.000246, -0.000223, -0.000244, -0.000213, -0.000245,
    -0.000221, -0.000243, -0.000212, -0.000244, -0.000221, -0.000243, -0.000213,
    -0.000244, -0.000222, -0.000242, -0.000211, -0.000244, -0.000221, -0.000241,
    -0.000209, -0.000243, -0.000222, -0.000241, -0.00021, -0.000243, -0.00022,
    -0.00024, -0.000211, -0.000242, -0.00022, -0.00024, -0.000209, -0.000242,
    -0.000218, -0.000241, -0.00021, -0.000241, -0.000218, -0.000239, -0.000209,
    -0.000241, -0.000218, -0.000238, -0.000207, -0.00024, -0.000217, -0.000238,
    -0.000207, -0.00024, -0.000215, -0.000239, -0.000206, -0.000239, -0.000217,
    -0.000235, -0.000206, -0.000239, -0.000215, -0.000238, -0.000207, -0.000238,
    -0.000216, -0.000236, -0.000206, -0.000238, -0.000215, -0.000236, -0.000206,
    -0.000237, -0.000215, -0.000236, -0.000206, -0.000237, -0.000215, -0.000235,
    -0.000205, -0.000237, -0.000215, -0.000235, -0.000205, -0.000236, -0.000214,
    -0.000234, -0.000203, -0.000236, -0.000213, -0.000234, -0.000203, -0.000235,
    -0.000213, -0.000234, -0.000203, -0.000235, -0.000213, -0.000233, -0.000203,
    -0.000234, -0.000212, -0.000233, -0.000204, -0.000234, -0.000212, -0.000232,
    -0.000203, -0.000233, -0.000212, -0.000232, -0.000202, -0.000233, -0.00021,
    -0.000232, -0.000201, -0.000233, -0.000212, -0.000231, -0.000204, -0.000232,
    -0.00021, -0.00023, -0.000201, -0.000232, -0.000209, -0.000231, -0.000202,
    -0.000231, -0.000211, -0.00023, -0.000201, -0.000231, -0.000209, -0.00023,
    -0.000201, -0.000231, -0.000208, -0.000229, -0.0002, -0.00023, -0.000208,
    -0.000229, -0.0002, -0.00023, -0.000208, -0.000228, -0.0002, -0.000229,
    -0.000207, -0.000228, -0.0002, -0.000229, -0.000206, -0.000227, -0.0002,
    -0.000229, -0.000206, -0.000228, -0.000198, -0.000228, -0.000205, -0.000227,
    -0.000199, -0.000228, -0.000205, -0.000227, -0.000199, -0.000227, -0.000205,
    -0.000226, -0.000198, -0.000227, -0.000205, -0.000226, -0.000197, -0.000227,
    -0.000201, -0.000226, -0.000198, -0.000226, -0.000205, -0.000225, -0.000196,
    -0.000226, -0.000203, -0.000224, -0.000196, -0.000225, -0.000203, -0.000225,
    -0.000196, -0.000225, -0.000202, -0.000224, -0.000195, -0.000225, -0.000203,
    -0.000224, -0.000195, -0.000224, -0.000201, -0.000223, -0.000195, -0.000224,
    -0.000202, -0.000223, -0.000195, -0.000223, -0.00019, -0.000209, -0.000183,
    -0.00021, -0.000189, -0.000209, -0.000182, -0.000209, -0.000188, -0.000209,
    -0.000182, -0.000209, -0.000188, -0.000208, -0.000182, -0.000209, -0.000187,
    -0.000208, -0.000182, -0.000208, -0.000188, -0.000207, -0.000181, -0.000208,
    -0.000188, -0.000207, -0.000181, -0.000208, -0.000186, -0.000207, -0.00018,
    -0.000207, -0.000187, -0.000207, -0.000181, -0.000207, -0.000185, -0.000206,
    -0.000181, -0.000207, -0.000185, -0.000206, -0.000179, -0.000206, -0.000186,
    -0.000205, -0.00018, -0.000206, -0.000185, -0.000205, -0.000179, -0.000206,
    -0.000185, -0.000205, -0.000178, -0.000205, -0.000185, -0.000204, -0.000179,
    -0.000205, -0.000184, -0.000204, -0.000178, -0.000205, -0.000183, -0.000204,
    -0.000179, -0.000204, -0.000184, -0.000204, -0.000177, -0.000204, -0.000183,
    -0.000203, -0.000177, -0.000204, -0.000183, -0.000203, -0.000177, -0.000203,
    -0.000183, -0.000202, -0.000177, -0.000203, -0.000183, -0.000202, -0.000179,
    -0.000203, -0.000182, -0.000202, -0.000176, -0.000202, -0.000181, -0.000202,
    -0.000177, -0.000202, -0.000184, -0.000201, -0.000176, -0.000202, -0.00018,
    -0.000201, -0.000176, -0.000201, -0.000181, -0.0002, -0.000175, -0.000201,
    -0.000181, -0.000199, -0.000174, -0.000201, -0.000179, -0.0002, -0.000174,
    -0.000201, -0.000181, -0.0002, -0.000175, -0.0002, -0.000181, -0.000199,
    -0.000174, -0.0002, -0.000181, -0.000198, -0.000173, -0.0002, -0.000178,
    -0.000199, -0.000174, -0.000199, -0.000179, -0.000198, -0.000174, -0.000199,
    -0.000181, -0.000198, -0.000174, -0.000199, -0.000179, -0.000198, -0.000173,
    -0.000198, -0.000177, -0.000198, -0.000174, -0.000198, -0.000176, -0.000198,
    -0.000173, -0.000198, -0.000176, -0.000197, -0.000173, -0.000198, -0.000176,
    -0.000197, -0.000174, -0.000197, -0.000176, -0.000196, -0.000172, -0.000197,
    -0.000176, -0.000197, -0.000171, -0.000197, -0.000176, -0.000196, -0.000168,
    -0.000196, -0.000182, -0.000196, -0.000171, -0.000196, -0.000174, -0.000196,
    -0.000173, -0.000196, -0.000176, -0.000191, -0.000172, -0.000196, -0.000171,
    -0.000194, -0.000171, -0.000195, -0.000176, -0.000195, -0.000169, -0.000195,
    -0.000174, -0.000194, -0.000166, -0.000195, -0.000174, -0.000194, -0.000168,
    -0.000194, -0.00017, -0.000194, -0.000169, -0.000194, -0.000174, -0.000193,
    -0.000169, -0.000194, -0.000174, -0.000193, -0.000169, -0.000194, -0.000173,
    -0.000192, -0.000168, -0.000193, -0.000175, -0.000192, -0.000167, -0.000193,
    -0.000173, -0.000192, -0.000167, -0.000193, -0.000171, -0.000191, -0.000167,
    -0.000192, -0.000173, -0.000192, -0.000171, -0.000192, -0.000172, -0.000191,
    -0.000167, -0.000192, -0.000171, -0.000191, -0.000168, -0.000192, -0.000171,
    -0.000191, -0.000167, -0.000191, -0.000171, -0.000191, -0.000167, -0.000191,
    -0.000171, -0.000191, -0.000166, -0.000191, -0.00017, -0.00019, -0.000167,
    -0.000191, -0.000171, -0.00019, -0.000166, -0.00019, -0.00017, -0.00019,
    -0.000165, -0.00019, -0.00017, -0.00019, -0.000166, -0.00019, -0.00015,
    -0.000167, -0.000146, -0.000168, -0.000151, -0.000167, -0.000146, -0.000167,
    -0.00015, -0.000167, -0.000148, -0.000167, -0.00015, -0.000167, -0.000147,
    -0.000167, -0.000149, -0.000167, -0.000147, -0.000167, -0.000149, -0.000166,
    -0.000146, -0.000167, -0.000149, -0.000166, -0.000144, -0.000166, -0.000148,
    -0.000166, -0.000146, -0.000166, -0.000148, -0.000165, -0.000145, -0.000166,
    -0.000148, -0.000166, -0.000146, -0.000166, -0.000149, -0.000165, -0.000145,
    -0.000165, -0.000145, -0.000165, -0.000145, -0.000165, -0.000148, -0.000165,
    -0.000145, -0.000165, -0.000145, -0.000165, -0.000144, -0.000165, -0.000148,
    -0.000164, -0.000145, -0.000165, -0.000147, -0.000164, -0.000144, -0.000164,
    -0.000147, -0.000164, -0.000145, -0.000164, -0.000148, -0.000164, -0.000143,
    -0.000164, -0.000147, -0.000163, -0.000144, -0.000164, -0.000146, -0.000163,
    -0.000144, -0.000163, -0.000145, -0.000163, -0.000142, -0.000163, -0.000148,
    -0.000163, -0.000142, -0.000163, -0.000144, -0.000163, -0.00014, -0.000163,
    -0.000145, -0.000162, -0.000142, -0.000163, -0.000144, -0.000162, -0.000142,
    -0.000162, -0.000145, -0.000162, -0.000141, -0.000162, -0.000145, -0.000162,
    -0.000142, -0.000162, -0.000144, -0.000162, -0.000142, -0.000162, -0.000145,
    -0.000162, -0.000143, -0.000162, -0.000144, -0.000161, -0.000141, -0.000161,
    -0.000144, -0.000161, -0.000143, -0.000161, -0.000144, -0.000161, -0.000141,
    -0.000161, -0.000143, -0.000161, -0.000142, -0.000161, -0.000143, -0.000161,
    -0.00014, -0.000161, -0.000142, -0.00016, -0.000141, -0.00016, -0.000143,
    -0.00016, -0.000141, -0.00016, -0.000143, -0.00016, -0.00014, -0.00016,
    -0.000145, -0.000159, -0.000141, -0.00016, -0.000143, -0.000159, -0.000139,
    -0.00016, -0.000143, -0.000159, -0.000139, -0.000159, -0.000141, -0.000159,
    -0.000139, -0.000159, -0.000143, -0.000159, -0.000139, -0.000159, -0.000141,
    -0.000159, -0.00014, -0.000159, -0.00014, -0.000158, -0.000138, -0.000159,
    -0.000141, -0.000158, -0.000138, -0.000158, -0.000143, -0.000158, -0.000139,
    -0.000158, -0.000141, -0.000158, -0.000137, -0.000158, -0.000142, -0.000158,
    -0.000138, -0.000158, -0.000141, -0.000157, -0.000138, -0.000158, -0.00014,
    -0.000157, -0.000138, -0.000157, -0.000139, -0.000157, -0.000138, -0.000157,
    -0.000141, -0.000157, -0.000139, -0.000157, -0.000139, -0.000156, -0.000139,
    -0.000157, -0.000138, -0.000157, -0.000137, -0.000157, -0.000138, -0.000156,
    -0.000137, -0.000156, -0.000139, -0.000156, -0.000138, -0.000156, -0.000139,
    -0.000156, -0.000137, -0.000156, -0.000139, -0.000156, -0.000138, -0.000156,
    -0.000139, -0.000155, -0.000136, -0.000156, -0.000138, -0.000156, -0.000136,
    -0.000155, -0.000139, -0.000155, -0.000137, -0.000155, -0.000139, -0.000155,
    -0.000136, -0.000155, -0.000138, -0.000155, -0.000138, -0.000155, -0.000139,
    -0.000155, -0.000136, -0.000155, -0.000138, -0.000155, -0.000137, -0.000155,
    -0.000137, -0.000154, -0.000136, -0.000154, -0.000137, -0.000154, -0.000135,
    -0.000154, -0.000137, -0.000154, -0.000135, -0.000154, -0.000137, -0.000154,
    -0.000134, -0.000154, -0.000136, -0.000154, -0.000135, -0.000154, -0.000137,
    -0.000154, -0.000136, -0.000154, -0.000136, -0.000153, -0.000135, -0.000153,
    -0.000137, -0.000153, -0.000135, -0.000153, -0.000136, -0.000153, -0.000134,
    -0.000153, -0.000136, -0.000153, -0.000134, -0.000153, -0.000137, -0.000153,
    -0.000133, -0.000153, -0.000136, -0.000153, -0.000134, -0.000153, -0.000135,
    -0.000152, -0.000133, -0.000152, -0.000135, -0.000152, -0.000134, -0.000152,
    -0.000134, -0.000152, -0.000134, -0.000152, -0.000135, -0.000152, -0.000134,
    -0.000152, -0.000134, -0.000152, -0.000134, -0.000152, -0.000135, -0.000151,
    -0.000131, -0.000151, -0.000134, -0.000151, -0.000134, -0.000151, -0.000136,
    -0.000151, -0.000134, -0.000151, -0.000136, -0.000151, -0.000134, -0.000151,
    -0.000133, -0.000151, -0.000133, -0.000151, -0.000134, -0.000151, -0.000133,
    -0.000151, -0.000133, -0.00015, -0.000132, -0.00015, -0.000132, -0.00015,
    -0.000134, -0.00015, -0.000125, -0.000149, -0.000135, -0.00015, -0.000131,
    -0.00015, -0.000135, -0.00015, -0.000132, -0.00015, -0.000132, -0.00015,
    -0.000133, -0.00015, -0.000133, -0.00015, -0.000131, -0.000149, -0.000132,
    -0.000149, -0.000134, -0.000149, -0.000131, -0.000149, -0.000133, -0.000149,
    -0.000131, -0.000149, -0.000135, -0.000149, -0.000133, -0.000149, -0.000133,
    -0.000149, -0.00013, -0.000149, -0.000131, -0.000149, -0.000131, -0.000149,
    -0.000131, -0.000148, -0.00013, -0.000148, -0.000133, -0.000148, -0.00013,
    -0.000148, -0.000132, -0.000148, -0.000129, -0.000148, -0.000132, -0.000148,
    -0.00013, -0.000148, -0.000131, -0.000148, -0.00013, -0.000148, -0.000131,
    -0.000148, -0.000131, -0.000148, -0.000123, -0.000139, -0.000124, -0.000139,
    -0.000125, -0.000139, -0.000123, -0.000139, -0.000123, -0.000139, -0.000122,
    -0.000138, -0.00012, -0.000138, -0.000123, -0.000138, -0.000124, -0.000138,
    -0.000123, -0.000138, -0.000123, -0.000138, -0.000122, -0.000138, -0.000123,
    -0.000138, -0.000121, -0.000138, -0.000123, -0.000138, -0.000122, -0.000138,
    -0.000122, -0.000137, -0.000123, -0.000138, -0.000122, -0.000137, -0.000121,
    -0.000137, -0.000122, -0.000137, -0.000121, -0.000137, -0.000121, -0.000137,
    -0.000121, -0.000137, -0.000122, -0.000137, -0.000122, -0.000137, -0.000123,
    -0.000137, -0.000121, -0.000137, -0.000121, -0.000137, -0.000121, -0.000137,
    -0.000121, -0.000137, -0.00012, -0.000137, -0.000122, -0.000136, -0.000121,
    -0.000136, -0.000121, -0.000136, -0.00012, -0.000136, -0.00012, -0.000136,
    -0.00012, -0.000136, -0.000121, -0.000136, -0.000119, -0.000136, -0.00012,
    -0.000136, -0.00012, -0.000136, -0.000121, -0.000136, -0.00012, -0.000136,
    -0.000121, -0.000136, -0.00012, -0.000136, -0.000121, -0.000136, -0.000119,
    -0.000136, -0.00012, -0.000135, -0.00012, -0.000135, -0.00012, -0.000135,
    -0.00012, -0.000135, -0.000119, -0.000135, -0.000119, -0.000135, -0.000119,
    -0.000135, -0.00012, -0.000135, -0.00012, -0.000135, -0.00012, -0.000135,
    -0.000119, -0.000135, -0.00012, -0.000135, -0.000119, -0.000135, -0.000118,
    -0.000135, -0.00012, -0.000134, -0.000119, -0.000134, -0.00012, -0.000134,
    -0.000119, -0.000134, -0.000119, -0.000134, -0.000119, -0.000134, -0.000119,
    -0.000134, -0.000119, -0.000134, -0.000118, -0.000134, -0.000118, -0.000134,
    -0.000119, -0.000134, -0.000117, -0.000134, -0.000118, -0.000134, -0.000118,
    -0.000134, -0.000117, -0.000134, -0.000118, -0.000133, -0.000118, -0.000133,
    -0.000119, -0.000133, -0.000118, -0.000133, -0.000118, -0.000133, -0.000118,
    -0.000133, -0.000119, -0.000133, -0.000117, -0.000133, -0.000115, -0.000133,
    -0.000111, -0.000133, -0.000115,
  ],
  imag: [
    0.0, 0.123894, 0.15737, 0.210952, -0.07615, -0.145613, -0.004471, -0.011613,
    0.068694, 0.033905, 0.119128, -0.001508, -0.005879, 0.004987, -0.012356,
    -0.002032, 0.002838, 0.00564, 0.016947, 0.003254, 0.012119, 0.001022,
    0.001041, -0.006764, 0.006971, 0.006519, 0.00636, -0.005381, 0.001966,
    0.003007, 0.000692, -0.003329, -0.003718, 0.003545, 0.00201, -0.000368,
    -0.002096, 0.001772, 0.002863, 0.000002, -0.002596, 0.002207, 0.002314,
    -0.001802, -0.0009, 0.000999, 0.002079, 0.001643, -0.00187, 0.001103,
    0.000801, 0.00173, -0.000759, 0.001411, -0.000436, 0.001145, -0.000783,
    0.000123, -0.000812, 0.000273, -0.000854, 0.000925, -0.000936, -0.001076,
    0.000695, 0.001443, 0.001081, 0.001177, -0.000836, -0.000766, -0.000941,
    0.001357, 0.000295, 0.000319, 0.000193, -0.000853, -0.000977, 0.001197,
    -0.00105, -0.001184, -0.000282, 0.001258, 0.001128, -0.000743, -0.001203,
    0.000408, -0.000523, -0.000786, -0.000708, -0.000819, -0.000563, -0.001193,
    0.000575, -0.000254, -0.000883, -0.000393, 0.00015, 0.000278, -0.000755,
    -0.001036, 0.000505, 0.000622, 0.000572, -0.000796, -0.000911, 0.001027,
    0.000188, 0.000287, 0.00103, -0.000007, -0.000679, -0.000637, -0.000238,
    0.000992, 0.000265, -0.000835, 0.000238, 0.000743, 0.000718, 0.00011,
    -0.000285, 0.000941, -0.000956, 0.000664, -0.000953, 0.000921, 0.00056,
    -0.000334, 0.000796, 0.000358, -0.000616, -0.000532, 0.000413, 0.000896,
    0.000792, -0.000906, 0.000177, -0.000526, 0.000062, -0.000074, -0.000657,
    -0.000682, 0.000754, 0.000233, -0.000733, -0.000887, -0.000387, -0.000653,
    -0.000071, 0.000132, -0.000301, -0.000616, -0.000867, -0.000867, -0.000111,
    -0.000509, -0.000777, -0.000656, 0.000567, -0.000847, -0.00063, -0.000458,
    -0.000078, 0.000355, 0.000813, -0.000546, -0.00052, -0.000688, -0.000492,
    -0.000013, -0.000816, 0.000302, 0.000713, -0.000567, 0.000523, 0.000223,
    0.000722, -0.000637, -0.000779, -0.000085, 0.000069, -0.000329, -0.000663,
    -0.00055, -0.000304, 0.000003, -0.00073, 0.00031, -0.000389, -0.000343,
    -0.000701, -0.000621, -0.000819, 0.000044, -0.00036, 0.000507, 0.00046,
    0.000671, 0.00021, -0.000212, -0.000265, -0.000476, -0.000799, -0.000541,
    -0.000783, -0.00067, -0.000642, -0.000639, -0.000562, -0.000546, -0.000555,
    -0.000437, -0.000294, -0.000183, -0.000175, -0.000178, -0.000076, 0.00001,
    -0.000071, -0.000143, -0.000044, 0.000041, -0.000093, -0.000185, -0.000064,
    0.000037, -0.00012, -0.000218, -0.000077, 0.000038, -0.00014, -0.000242,
    -0.000086, 0.00004, -0.000155, -0.00026, -0.000093, 0.000043, -0.000168,
    -0.000275, -0.000097, 0.000048, -0.000178, -0.000304, -0.000106, 0.000056,
    -0.000197, -0.000314, -0.000108, 0.000061, -0.000204, -0.000322, -0.000109,
    0.000067, -0.000211, -0.000329, -0.00011, 0.000072, -0.000214, -0.000334,
    -0.00011, 0.000077, -0.000218, -0.000338, -0.00011, 0.000082, -0.000221,
    -0.000341, -0.000109, 0.000087, -0.000224, -0.000344, -0.000108, 0.000092,
    -0.000225, -0.000346, -0.000107, 0.000096, -0.000226, -0.000348, -0.000106,
    0.000101, -0.000227, -0.000349, -0.000105, 0.000105, -0.000228, -0.000349,
    -0.000103, 0.000109, -0.000227, -0.00035, -0.000103, 0.000113, -0.000228,
    -0.00035, -0.000101, 0.000116, -0.000228, -0.000351, -0.000101, 0.00012,
    -0.000227, -0.00035, -0.000098, 0.000123, -0.00022, -0.00035, -0.000097,
    0.000126, -0.00023, -0.000349, -0.000097, 0.00013, -0.000224, -0.000346,
    -0.000093, 0.000131, -0.000222, -0.000347, -0.000092, 0.000135, -0.00022,
    -0.000345, -0.000091, 0.000137, -0.000221, -0.000345, -0.00009, 0.00014,
    -0.000214, -0.000344, -0.00009, 0.000143, -0.000211, -0.000343, -0.000086,
    0.000145, -0.000211, -0.000341, -0.000085, 0.000146, -0.000213, -0.00034,
    -0.000084, 0.000148, -0.000208, -0.000338, -0.000083, 0.00015, -0.000209,
    -0.000337, -0.000081, 0.000152, -0.000207, -0.000336, -0.00008, 0.000154,
    -0.000206, -0.000334, -0.000079, 0.000155, -0.000202, -0.000334, -0.000078,
    0.000153, -0.000194, -0.000322, -0.000074, 0.000154, -0.000194, -0.00032,
    -0.000073, 0.000155, -0.000191, -0.000319, -0.000072, 0.000157, -0.000188,
    -0.000318, -0.00007, 0.00016, -0.000185, -0.000316, -0.00007, 0.000159,
    -0.000188, -0.000316, -0.000068, 0.00016, -0.000184, -0.000313, -0.000068,
    0.00016, -0.000182, -0.000312, -0.000067, 0.000163, -0.00018, -0.000311,
    -0.000065, 0.000162, -0.000178, -0.00031, -0.000065, 0.000164, -0.000176,
    -0.000307, -0.000063, 0.000165, -0.000175, -0.000305, -0.000062, 0.000166,
    -0.000173, -0.000304, -0.000061, 0.000167, -0.000173, -0.000303, -0.000059,
    0.000168, -0.000171, -0.000302, -0.00006, 0.000168, -0.000168, -0.000302,
    -0.00006, 0.000168, -0.000167, -0.000297, -0.00006, 0.000166, -0.000169,
    -0.000299, -0.000058, 0.00017, -0.000157, -0.000297, -0.000057, 0.000168,
    -0.00016, -0.000294, -0.000054, 0.000172, -0.00015, -0.000303, -0.00006,
    0.000171, -0.00012, -0.000293, -0.00006, 0.000166, -0.000164, -0.000301,
    -0.000074, 0.000144, -0.000131, -0.000233, -0.000046, 0.000154, -0.000127,
    -0.000251, -0.000044, 0.000148, -0.000126, -0.000245, -0.000042, 0.000148,
    -0.000128, -0.000246, -0.000043, 0.00015, -0.000126, -0.000241, -0.000043,
    0.000149, -0.000125, -0.000242, -0.000043, 0.000148, -0.000122, -0.000242,
    -0.000042, 0.000149, -0.000123, -0.000241, -0.000041, 0.00015, -0.000123,
    -0.000239, -0.000039, 0.000149, -0.000119, -0.000238, -0.000039, 0.000149,
    -0.000117, -0.000236, -0.00004, 0.00015, -0.00012, -0.000237, -0.000038,
    0.000151, -0.000115, -0.000234, -0.000038, 0.00015, -0.000123, -0.000235,
    -0.000039, 0.000154, -0.000115, -0.000233, -0.000036, 0.000153, -0.00009,
    -0.000235, -0.000039, 0.000152, -0.000117, -0.000233, -0.000038, 0.000151,
    -0.000119, -0.000232, -0.000039, 0.000148, -0.000111, -0.00023, -0.000037,
    0.000149, -0.000111, -0.000229, -0.000037, 0.00015, -0.00011, -0.000228,
    -0.000036, 0.000149, -0.000107, -0.000227, -0.000034, 0.00015, -0.000105,
    -0.000227, -0.000035, 0.000152, -0.000105, -0.000225, -0.000033, 0.000151,
    -0.000104, -0.000225, -0.000034, 0.000148, -0.000104, -0.000223, -0.000033,
    0.000151, -0.000099, -0.000222, -0.000033, 0.00015, -0.000104, -0.000222,
    -0.000032, 0.000151, -0.0001, -0.000221, -0.000032, 0.000149, -0.000101,
    -0.000221, -0.000032, 0.000151, -0.000098, -0.00022, -0.000032, 0.000151,
    -0.000098, -0.000219, -0.000032, 0.000151, -0.000095, -0.000218, -0.000031,
    0.00015, -0.000096, -0.000216, -0.00003, 0.000149, -0.000096, -0.000216,
    -0.00003, 0.00015, -0.000096, -0.000216, -0.00003, 0.00015, -0.000096,
    -0.000214, -0.00003, 0.000149, -0.00009, -0.000214, -0.00003, 0.000128,
    -0.00008, -0.000183, -0.000025, 0.000128, -0.000076, -0.000182, -0.000025,
    0.000128, -0.000078, -0.000181, -0.000025, 0.000128, -0.000075, -0.00018,
    -0.000024, 0.000128, -0.000067, -0.000181, -0.000025, 0.000127, -0.000079,
    -0.000181, -0.000025, 0.000129, -0.000078, -0.000177, -0.000024, 0.000125,
    -0.000076, -0.000178, -0.000023, 0.000129, -0.000067, -0.000177, -0.000023,
    0.000129, -0.000074, -0.000176, -0.000023, 0.000128, -0.000072, -0.000175,
    -0.000024, 0.000126, -0.000066, -0.000176, -0.000021, 0.000127, -0.00007,
    -0.000176, -0.000022, 0.000126, -0.000069, -0.000175, -0.000021, 0.000127,
    -0.00007, -0.000175, -0.000022, 0.00013, -0.000068, -0.000174, -0.000022,
    0.000128, -0.000067, -0.000172, -0.00002, 0.000126, -0.000068, -0.000172,
    -0.000021, 0.000126, -0.000067, -0.000171, -0.000021, 0.000125, -0.000068,
    -0.000171, -0.000021, 0.000127, -0.000067, -0.00017, -0.00002, 0.000125,
    -0.000069, -0.00017, -0.00002, 0.000126, -0.000065, -0.00017, -0.000021,
    0.000126, -0.000066, -0.000169, -0.000019, 0.000126, -0.000063, -0.000167,
    -0.000019, 0.000124, -0.000061, -0.000168, -0.00002, 0.000126, -0.000062,
    -0.000165, -0.000019, 0.000125, -0.000062, -0.000166, -0.000019, 0.000126,
    -0.000063, -0.000166, -0.000018, 0.000125, -0.000059, -0.000166, -0.000019,
    0.000125, -0.00006, -0.000164, -0.000018, 0.000125, -0.000061, -0.000165,
    -0.000019, 0.000126, -0.00006, -0.000163, -0.000017, 0.000126, -0.000056,
    -0.000164, -0.000017, 0.000125, -0.000055, -0.000162, -0.000018, 0.000124,
    -0.000055, -0.000161, -0.000017, 0.000123, -0.000057, -0.00016, -0.000017,
    0.000124, -0.000059, -0.00016, -0.000017, 0.000123, -0.000046, -0.000161,
    -0.000016, 0.000127, -0.000049, -0.000159, -0.000016, 0.000124, -0.000058,
    -0.000162, -0.000016, 0.000124, -0.000051, -0.000159, -0.000016, 0.000122,
    -0.000055, -0.000158, -0.000017, 0.000125, -0.000053, -0.000157, -0.000016,
    0.000123, -0.000052, -0.000157, -0.000016, 0.000122, -0.000055, -0.000157,
    -0.000017, 0.000123, -0.00005, -0.000155, -0.000015, 0.000125, -0.000053,
    -0.000157, -0.000016, 0.000123, -0.000049, -0.000156, -0.000014, 0.000123,
    -0.000049, -0.000155, -0.000016, 0.000124, -0.000054, -0.000155, -0.000015,
    0.000121, -0.000049, -0.000155, -0.000015, 0.000122, -0.00005, -0.000153,
    -0.000014, 0.00012, -0.000048, -0.000154, -0.000014, 0.000122, -0.000045,
    -0.000151, -0.000013, 0.000123, -0.000048, -0.000152, -0.000015, 0.000125,
    -0.000045, -0.000148, -0.000013, 0.000124, -0.000035, -0.000154, -0.000015,
    0.000123, -0.000042, -0.00015, -0.000014, 0.000121, -0.000049, -0.00015,
    -0.000013, 0.000122, -0.000049, -0.00015, -0.000013, 0.00012, -0.00004,
    -0.000153, -0.000014, 0.000122, -0.000043, -0.000151, -0.000015, 0.000116,
    -0.000048, -0.000152, -0.000014, 0.00012, -0.000043, -0.00015, -0.000015,
    0.00012, -0.000045, -0.00015, -0.000012, 0.00012, -0.000044, -0.000147,
    -0.000012, 0.000119, -0.00004, -0.000148, -0.00001, 0.00012, -0.000042,
    -0.000151, -0.000014, 0.000124, -0.000038, -0.000146, -0.000011, 0.000119,
    -0.000055, -0.000154, -0.000015, 0.000121, -0.000025, -0.000144, -0.000014,
    0.000115, -0.000067, -0.000148, -0.000024, 0.000118, -0.000045, -0.000116,
    -0.000014, 0.000107, -0.000052, -0.000129, -0.000015, 0.000076, -0.000038,
    -0.000126, -0.000005, 0.0001, -0.000023, -0.000131, -0.000013, 0.000106,
    -0.000046, -0.000126, -0.000013, 0.000099, -0.000057, -0.000128, -0.000008,
    0.000105, -0.000042, -0.000125, -0.000007, 0.000104, -0.000028, -0.000127,
    -0.00001, 0.000102, -0.000033, -0.000129, -0.000008, 0.000106, -0.000033,
    -0.000126, -0.000011, 0.000102, -0.000031, -0.000125, -0.000012, 0.000103,
    -0.000025, -0.000125, -0.000011, 0.000103, -0.000042, -0.000128, -0.000011,
    0.000105, -0.000031, -0.000124, -0.000009, 0.000101, -0.000031, -0.000125,
    -0.00001, 0.000104, -0.000029, -0.000123, -0.000009, 0.000107, -0.00004,
    -0.000123, -0.00001, 0.000104, -0.000032, -0.000123, -0.00001, 0.000103,
    -0.000026, -0.000124, -0.000008, 0.000103, -0.000031, -0.000122, -0.00001,
    0.000105, -0.000034, -0.000122, -0.00001, 0.000105, -0.00003, -0.00012,
    -0.00001, 0.000102, -0.000033, -0.000122, -0.000009, 0.000102, -0.000031,
    -0.000125, -0.000009, 0.0001, -0.000034, -0.000122, -0.00001, 0.000102,
    -0.000035, -0.00012, -0.000008, 0.000102, -0.000027, -0.000122, -0.000009,
    0.000104, -0.000022, -0.000119, -0.00001, 0.000102, -0.000031, -0.00012,
    -0.000007, 0.000102, -0.000033, -0.000122, -0.00001, 0.000104, -0.000032,
    -0.000122, -0.000009, 0.000106, -0.000022, -0.000122, -0.000009, 0.000101,
    -0.000047, -0.000121, -0.000011, 0.000103, -0.000023, -0.000119, -0.000007,
    0.0001, -0.000034, -0.000119, -0.000009, 0.000102, -0.000028, -0.000119,
    -0.000008, 0.000101, -0.000029, -0.000118, -0.000008, 0.000099, -0.000028,
    -0.000118, -0.000009, 0.000099, -0.000028, -0.000117, -0.000008, 0.000101,
    -0.000027, -0.000121, -0.000009, 0.000101, -0.00003, -0.000119, -0.000007,
    0.000101, -0.000027, -0.000118, -0.000009, 0.000099, -0.000025, -0.000118,
    -0.000008, 0.0001, -0.000027, -0.000116, -0.000009, 0.0001, -0.00003,
    -0.000116, -0.000009, 0.000097, -0.000027, -0.000116, -0.000007, 0.000101,
    -0.000024, -0.000117, -0.000008, 0.000097, -0.000025, -0.000111, -0.000009,
    0.000098, -0.00003, -0.000115, -0.000009, 0.0001, -0.000023, -0.000114,
    -0.000006, 0.000096, -0.000024, -0.000114, -0.000007, 0.000099, -0.000023,
    -0.000114, -0.000007, 0.0001, -0.000028, -0.000114, -0.000007, 0.000098,
    -0.000022, -0.000113, -0.000007, 0.000098, -0.000023, -0.000112, -0.000008,
    0.000098, -0.000025, -0.000112, -0.000007, 0.000099, -0.000025, -0.000112,
    -0.000006, 0.000098, -0.000019, -0.000114, -0.000007, 0.0001, -0.000019,
    -0.000112, -0.000007, 0.000099, -0.00002, -0.00011, -0.000006, 0.000098,
    -0.000024, -0.000111, -0.000007, 0.000097, -0.000021, -0.000112, -0.000005,
    0.000104, -0.000019, -0.00011, -0.000007, 0.000095, -0.000022, -0.000112,
    -0.000008, 0.000099, -0.000023, -0.000112, -0.000008, 0.000097, -0.000016,
    -0.00011, -0.000007, 0.000099, -0.000022, -0.000111, -0.000005, 0.000097,
    -0.00002, -0.000111, -0.000005, 0.000098, -0.000023, -0.00011, -0.000006,
    0.000097, -0.000021, -0.00011, -0.000006, 0.00009, -0.000019, -0.000103,
    -0.000005, 0.000091, -0.000018, -0.000104, -0.000005, 0.000093, -0.000014,
    -0.000102, -0.000006, 0.000091, -0.00002, -0.000103, -0.000005, 0.000092,
    -0.000019, -0.000102, -0.000007, 0.000091, -0.000023, -0.000103, -0.000005,
    0.000089, -0.000019, -0.000102, -0.000006, 0.000092, -0.000017, -0.000103,
    -0.000004, 0.00009, -0.000016, -0.0001, -0.000005, 0.000092, -0.000016,
    -0.0001, -0.000006, 0.000092, -0.000013, -0.000103, -0.000005, 0.00009,
    -0.000018, -0.000101, -0.000005, 0.00009, -0.000021, -0.000102, -0.000007,
    0.000091, -0.000015, -0.000102, -0.000005, 0.000088, -0.000019, -0.0001,
    -0.000004, 0.000091, -0.000019, -0.000102, -0.000005, 0.000091, -0.000016,
    -0.0001, -0.000005, 0.000088, -0.000015, -0.000102, -0.000007, 0.00009,
    -0.00002, -0.000101, -0.000007, 0.000089, -0.00002, -0.0001, -0.000004,
    0.000088, -0.000022, -0.0001, -0.000005, 0.000088, -0.00002, -0.000096,
    -0.000005, 0.00009, -0.000012, -0.0001, -0.000006, 0.00009, -0.000008,
    -0.000098, -0.000005, 0.000083, -0.000024, -0.000099, -0.000006, 0.000092,
    -0.000017, -0.000099, -0.000007, 0.000089, -0.000021, -0.000099, -0.000005,
    0.000087, -0.000029, -0.0001, -0.000005, 0.00009, -0.000014, -0.0001,
    -0.000006, 0.000086, -0.000018, -0.000098, -0.000006, 0.000086, -0.000019,
    -0.000099, -0.000005, 0.000085, -0.000024, -0.0001, -0.000005, 0.00009,
    -0.000014, -0.000098, -0.000006, 0.000087, -0.000017, -0.000098, -0.000005,
    0.000084, -0.000015, -0.000097, -0.000004, 0.000087, -0.000012, -0.000097,
    -0.000006, 0.000089, -0.000015, -0.000096, -0.000003, 0.00009, -0.000009,
    -0.000097, -0.000004, 0.000089, -0.00002, -0.000095, -0.000003, 0.00009,
    -0.000012, -0.000093, -0.000003, 0.000089, -0.000016, -0.000097, -0.000005,
    0.000089, -0.000009, -0.000098, -0.000005, 0.000088, -0.000014, -0.000102,
    -0.000002, 0.000074, -0.000012, -0.000096, 0.000012, 0.000091, -0.000007,
    -0.000092, -0.000003, 0.000086, -0.000043, -0.000094, -0.0, 0.000095,
    -0.00002, -0.000094, -0.000005, 0.000084, -0.000005, -0.000098, 0.0,
    0.000089, -0.000019, -0.000101, -0.000006, 0.000087, -0.000012, -0.000098,
    -0.000005, 0.000095, -0.000016, -0.000096, -0.000007, 0.000085, -0.000019,
    -0.000096, -0.000005, 0.000086, -0.000014, -0.000094, -0.000004, 0.000086,
    -0.000024, -0.000096, -0.000003, 0.000082, -0.000017, -0.000096, -0.000003,
    0.000086, -0.000023, -0.000096, -0.000004, 0.000088, -0.000026, -0.000097,
    -0.000003, 0.000084, -0.0, -0.000087, -0.000007, 0.000085, -0.000016,
    -0.000095, -0.000002, 0.000088, -0.000018, -0.000093, -0.000004, 0.000086,
    -0.00001, -0.000093, -0.000003, 0.000087, -0.000012, -0.000094, -0.000004,
    0.000086, -0.000012, -0.000094, -0.000005, 0.000087, -0.000013, -0.000093,
    -0.000005, 0.000085, -0.000017, -0.000093, -0.000005, 0.000085, -0.000013,
    -0.000094, -0.000002, 0.000085, -0.000011, -0.000093, -0.000005, 0.000076,
    -0.00001, -0.000082, -0.000003, 0.000073, -0.000013, -0.000082, -0.000002,
    0.000075, -0.00001, -0.000078, -0.000005, 0.000074, -0.000008, -0.000079,
    -0.000002, 0.000075, -0.000007, -0.000079, -0.000003, 0.000075, -0.000007,
    -0.000079, -0.000001, 0.000074, -0.00001, -0.000084, -0.000003, 0.000075,
    -0.00001, -0.00008, -0.000001, 0.000075, -0.000016, -0.00008, -0.000001,
    0.000075, -0.000007, -0.000078, -0.000004, 0.000073, -0.000007, -0.000079,
    -0.000002, 0.000079, -0.000009, -0.000079, -0.000001, 0.000072, -0.00001,
    -0.000079, -0.000002, 0.000078, -0.00001, -0.00008, -0.000002, 0.000073,
    -0.000011, -0.000078, -0.000001, 0.000073, -0.000009, -0.000079, -0.000004,
    0.000074, -0.000011, -0.000078, -0.000004, 0.000071, -0.000009, -0.00008,
    -0.000001, 0.000073, -0.000013, -0.000078, -0.000002, 0.000074, -0.000005,
    -0.000077, -0.000003, 0.000075, 0.000002, -0.00008, -0.000002, 0.000069,
    -0.00001, -0.00008, -0.000002, 0.000077, 0.000001, -0.000084, -0.000002,
    0.000075, -0.000011, -0.00008, -0.000001, 0.000075, -0.000003, -0.000079,
    -0.000004, 0.000073, -0.000012, -0.00008, -0.000001, 0.000073, -0.000006,
    -0.000077, -0.000002, 0.000073, -0.000007, -0.000078, -0.000002, 0.000072,
    -0.000006, -0.000075, -0.000002, 0.000073, -0.00001, -0.000079, -0.000003,
    0.000073, -0.000008, -0.000075, -0.000002, 0.000072, -0.000006, -0.000077,
    -0.000002, 0.000073, -0.000006, -0.000075, -0.000003, 0.000073, -0.000003,
    -0.000078, -0.000002, 0.000074, -0.000008, -0.000076, -0.000002, 0.000072,
    -0.00001, -0.000077, -0.000002, 0.000073, -0.000004, -0.000078, -0.000003,
    0.000067, -0.000011, -0.000076, -0.000002, 0.000072, -0.000007, -0.000078,
    -0.000002, 0.000071, -0.000006, -0.000077, -0.000002, 0.000074, -0.0,
    -0.000078, -0.000002, 0.00007, -0.000009, -0.000077, -0.000002, 0.000073,
    -0.000004, -0.000076, -0.000002, 0.000074, -0.000008, -0.000078, -0.000001,
    0.000073, -0.000004, -0.000078, -0.000002, 0.000068, -0.000005, -0.000075,
    -0.000003, 0.000072, -0.000013, -0.000078, -0.000002, 0.00007, -0.00001,
    -0.000077, -0.000002, 0.000072, -0.000009, -0.000076, -0.000002, 0.000072,
    -0.000005, -0.000075, -0.000002, 0.000073, -0.000006, -0.000075, -0.000002,
    0.00007, -0.000003, -0.000073, -0.000002, 0.000073, -0.000013, -0.000073,
    -0.000003, 0.000074, -0.000006, -0.000077, 0.000001, 0.000073, -0.000009,
    -0.000076, -0.000002, 0.000072, -0.000008, -0.000074, -0.000003, 0.000072,
    -0.000007, -0.000074, -0.000003, 0.000072, -0.000003, -0.000073, -0.000003,
    0.000071, -0.000011, -0.000076, 0.000001, 0.000072, -0.000002, -0.000075,
    -0.000004, 0.00007, -0.000003, -0.000074, -0.000002, 0.00007, -0.000007,
    -0.000075, -0.000002, 0.000072, -0.000004, -0.000071, -0.000001, 0.000069,
    -0.000008, -0.000074, -0.000002, 0.00007, -0.000004, -0.000073, -0.0,
    0.000072, -0.000007, -0.000074, -0.000002, 0.00007, -0.000002, -0.000074,
    -0.0, 0.000071, 0.0, -0.000074, -0.000002, 0.00007, -0.000003, -0.000076,
    -0.000001, 0.000073, -0.000007, -0.000074, -0.000003, 0.00007, -0.000002,
    -0.000071, -0.000002, 0.000072, -0.00001, -0.000073, -0.000001, 0.000069,
    -0.000009, -0.000072, -0.000002, 0.00007, -0.000004, -0.000075, -0.000001,
    0.00007, -0.000008, -0.000074, -0.000002, 0.000069, 0.000002, -0.000076,
    -0.000002, 0.00007, -0.000001, -0.000073, -0.0, 0.000071, -0.000002,
    -0.000074, -0.000001, 0.000071, -0.000009, -0.000072, 0.0, 0.000072,
    0.000004, -0.000071, -0.000001, 0.00007, -0.000009, -0.000072, -0.0,
    0.000071, -0.000004, -0.000072, -0.000002, 0.000068, -0.000015, -0.000076,
    -0.000003, 0.000071, -0.000008, -0.000071, -0.000004, 0.000065, -0.000001,
    -0.00007, -0.000001, 0.000066, -0.000005, -0.000069, 0.0, 0.000072,
    -0.000002, -0.00007, -0.000003, 0.000068, -0.000006, -0.000071, -0.000002,
    0.000071, 0.000005, -0.000073, -0.000004, 0.000072, -0.000013, -0.000069,
    -0.0, 0.000084, 0.000015, -0.000066, 0.000001, 0.000074, 0.000007,
    -0.000065, -0.000001, 0.000071, 0.000001, -0.00007, -0.0, 0.000069,
    -0.000001, -0.000069, -0.000002, 0.000073, -0.000006, -0.000071, -0.000007,
    0.000065, -0.000008, -0.000071, -0.000005, 0.000068, -0.000014, -0.000071,
    -0.000004, 0.000064, -0.000005, -0.000068, 0.000001, 0.000066, -0.000002,
    -0.000072, 0.0, 0.000071, -0.0, -0.00007, -0.000001, 0.00007, -0.000009,
    -0.000072, -0.000003, 0.000066, -0.000008, -0.000072, -0.000001, 0.000068,
    0.000001, -0.000073, -0.000002, 0.000068, 0.000001, -0.00007, 0.000001,
    0.000069, -0.000004, -0.00007, -0.000003, 0.000068, -0.000005, -0.000069,
    0.000001, 0.000065, -0.000008, -0.000062, -0.0, 0.000061, 0.000002,
    -0.000065, -0.000001, 0.000064, -0.000004, -0.000065, -0.0, 0.000068,
    -0.000003, -0.000064, -0.000001, 0.000062, -0.000002, -0.000063, -0.000002,
    0.000063, 0.000002, -0.000064, -0.0, 0.000062, -0.000005, -0.000067, 0.0,
    0.000062, -0.000005, -0.000065, -0.000001, 0.000064, -0.000007, -0.000061,
    0.000001, 0.000063, 0.000008, -0.000066, 0.0, 0.000064, 0.000003, -0.000065,
    -0.0, 0.000064, -0.000001, -0.000065, -0.000002, 0.000062, 0.000002,
    -0.000062, -0.0, 0.000059, 0.0, -0.000064, -0.000003, 0.000064, 0.000005,
    -0.000063, -0.000001, 0.000064, 0.000001, -0.000065, 0.000001, 0.000062,
    -0.000007, -0.000064, 0.0, 0.000063, -0.000005, -0.000065, -0.000002,
    0.000065, -0.000005, -0.000064, -0.000001, 0.000062, -0.000002, -0.000065,
    -0.000001, 0.000064, 0.000004, -0.000064, -0.0, 0.000061, -0.000004,
    -0.000064, -0.0, 0.000062, 0.000001, -0.000064, -0.000001, 0.000062,
    -0.000002, -0.000065, -0.0, 0.000062, 0.000001, -0.000062, -0.000002,
    0.000063, 0.000005, -0.000063, -0.000001, 0.000064, 0.000002, -0.000063,
    0.0, 0.000064, 0.000001, -0.000063, 0.000001, 0.000062, -0.000003,
    -0.000061, -0.000001, 0.000062, 0.000001, -0.000062, -0.000001, 0.000064,
    -0.000002, -0.000064, -0.0, 0.000061, -0.0, -0.000063, -0.000002, 0.00006,
    0.000002, -0.000063, -0.0, 0.000062, 0.0, -0.000063, -0.000002, 0.000063,
    -0.000001, -0.000063, 0.0, 0.000064, 0.0, -0.000063, 0.0, 0.000062,
    0.000003, -0.000064, 0.000001, 0.000063, -0.000001, -0.000062, -0.000001,
    0.000064, -0.000003, -0.000063, 0.000002, 0.000062, -0.000002, -0.000061,
    -0.0, 0.000061, 0.000007, -0.000063, -0.000001, 0.000062, 0.000013,
    -0.00006, 0.000002, 0.000063, -0.000005, -0.000067, -0.000002, 0.000074,
    -0.0, -0.000066,
  ],
};
